import { GetServerSidePropsContext } from 'next'
import dynamic from 'next/dynamic'
import { initGeoIpDataBase } from '@root/pageHelpers/geoip'
import { getMarketByIp, getMarketByPreferredLanguage, MarketIdentification, reconcileMarketValues } from '@root/pageHelpers/marketIdentifier'

const SPA = dynamic(() => import('../index'), {
  ssr: false,
})

export type NextAppProps = {
  market: string | null
  marketIdentification: MarketIdentification
}

export default function App({ market, marketIdentification }: Readonly<NextAppProps>) {
  return <SPA market={market} marketIdentification={marketIdentification} />
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  await initGeoIpDataBase()
  const marketByIp = getMarketByIp(context)
  const marketByLang = getMarketByPreferredLanguage(context)
  const marketIdentification = reconcileMarketValues({ marketByIp, marketByLang })

  return {
    props: {
      market: process.env.NEXT_PUBLIC_MARKET ? process.env.NEXT_PUBLIC_MARKET : null,
      marketIdentification
    }
  }
}
